import React, { CSSProperties } from 'react';

import { Layout } from '../../components/common';
import { MetaData } from '../../components/common/meta';
import { ProgressBarDesktop } from '../../components/ProgressBar/ProgressBarDesktop';
import RenderOnVisible from '../../components/RenderOnVisible';
import {
  renderGhostAuthorAside,
  renderGhostChatBubbles,
  renderGhostImageFloat,
  renderGhostInlineTOC,
} from '../../utils/ghostSnippets';
import trustAllScripts from '../../utils/trustAllScripts';

import style from '../../styles/pages/post.scss';

import cn from 'classnames';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Sticky, StickyContainer } from 'react-sticky';

interface IPageProps {
  pageContext: any;
  data: {
    ghostPage: {
      codeinjection_styles: any;
      title: string;
      html: string;
      feature_image?: string;
      slug: string;
      tags: { name: string; slug: string }[];
    };
    allGhostAuthor: {
      edges: {
        node: {
          name: string;
          slug: string;
          profile_image: string;
          bio: string;
        };
      }[];
    };
  };
  location: Location;
}

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */

// RenderOnVisible since content not visible on most mobile screens
// Extracted since onRouteUpdate from gatsby-browser.js runs before mounted
const Content = ({
  content,
  allGhostAuthor,
}: {
  content: string;
  allGhostAuthor: {
    edges: {
      node: {
        name: string;
        slug: string;
        profile_image: string;
        bio: string;
      };
    }[];
  };
}) => {
  React.useEffect(() => {
    trustAllScripts();

    renderGhostImageFloat();

    renderGhostInlineTOC();

    renderGhostChatBubbles(allGhostAuthor.edges);

    renderGhostAuthorAside(allGhostAuthor.edges);
  }, []);

  if (!content) {
    return null;
  }

  return (
    <section
      className="load-external-scripts"
      dangerouslySetInnerHTML={{
        __html: content
          // lazy load images
          ?.replace(/<img/gm, '<img loading="lazy"')
          // lazy load iframes
          .replace(/<iframe/gm, '<iframe loading="lazy"')
          // open external links in new tab
          .replace(
            /<a[\s]+href="(?!(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(((?!go))[a-z0-9]*)([\-\.]*thinksaveretire+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)/gm,
            '<a target="_blank" href="'
          ),
      }}
    />
  );
};

const Page = ({ data, location, pageContext }: IPageProps) => {
  const { ghostPage, allGhostAuthor } = data;

  const { age } = pageContext;

  ghostPage.title = ghostPage.title.replace(/DYNAMIC_AGE/g, age);
  ghostPage.html = ghostPage.html?.replace(/DYNAMIC_AGE/g, age);

  const { feature_image, title, html = '' } = ghostPage;

  const stylePost = style;

  React.useEffect(() => {
    renderGhostImageFloat();

    renderGhostInlineTOC();

    renderGhostChatBubbles(allGhostAuthor.edges);

    renderGhostAuthorAside(allGhostAuthor.edges);
  }, []);

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Layout headerClassName={style.header} mainClass={style.main} isArticle>
        <StickyContainer className={stylePost.sticky}>
          <Sticky>
            {({ style, isSticky }: { style: CSSProperties; isSticky: boolean }) => (
              <div style={{ ...style }}>
                <RenderOnVisible>
                  <ProgressBarDesktop
                    className={isSticky ? stylePost.stickyPrograssBar : ''}
                    title={title}
                    content={html}
                  />
                </RenderOnVisible>
              </div>
            )}
          </Sticky>
          <div
            className={cn(style.page, style.dynamicPost, {
              [style.featureImagePage]: !feature_image,
            })}
          >
            <div className="container">
              <article className="col-12 col-lg-8 m-auto">
                <h1>{title}</h1>
                <figure
                  className={cn(style.customArticleFeatureImage, style.singleArticleFeatureImage)}
                >
                  <img src={feature_image} alt={title} />
                </figure>
                <p className={style.disclaimerText}>
                  <Link to="/disclosure/">Click here to read our full disclosure.</Link>
                </p>
                {/* The main page content */}
                <Content content={html} allGhostAuthor={allGhostAuthor} />
              </article>
            </div>
          </div>
        </StickyContainer>
      </Layout>
    </>
  );
};

export default Page;

export const postQuery = graphql`
  query {
    ghostPage(slug: { eq: "early-retirement-steps-for-x-year-olds" }) {
      ...GhostPageFields
    }
    allGhostAuthor {
      edges {
        node {
          name
          slug
          bio
          profile_image
        }
      }
    }
  }
`;
